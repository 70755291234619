




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Konva from "konva";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import api from "@/api/api";
import {
  Camera,
  CameraCreate,
  CameraUpdate,
  CameraDetails,
  Server,
  LprCameraType,
  LprCameraDirection,
  EdgeDevice,
  ParkingZone,
} from "@/api/models";
import { Point } from "geojson";
import ViewStreamHelpPopup from "@/components/ViewStreamHelpPopup.vue";
import CameraMapDetails from "@/components/CameraMapDetails.vue";

type VForm = Vue & { resetValidation: () => boolean };

const DEFAULT_THRESHOLD = 0.6;
const DEFAULT_FLOCK_THRESHOLD = 0.75;

export default Vue.extend({
  name: "CameraForm",

  components: { ViewStreamHelpPopup, CameraMapDetails },

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    existingCameraDetails: {
      type: Object,
      required: false,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
    cameraMapEditingEnabled: {
      type: Boolean,
      default: false,
    },
    garbledImageDetectionLot: {
      type: Boolean,
      default: false,
    },
    flockNetworkId: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    tab: "overview",

    stageWidth: 514,
    stageHeight: 300,
    allFieldsValid: false,
    cameraId: null as number | null,
    isLoadingCameraData: false,
    name: "",
    streamUrl: "",
    lprStreamUrl: null as string | null,
    publicStreamUrl: "",
    lat: 0,
    lng: 0,
    comment: "" as string | null,
    isCameraGarbledImageDetectionFeatureEnabled: true,
    inferenceProcessingMethodItems: ["cloud"],
    inferenceProcessingMethod: "cloud",
    lprInferenceProcessingMethod: "cloud",
    showEdgeDeviceSelect: false,
    servers: {
      selectedId: null as string | null,
      items: [] as Array<Server>,
      isLoading: false,
    },
    edgeDevices: {
      selectedId: null as string | null,
      selectedLprId: null as string | null,
      items: [] as Array<EdgeDevice>,
      isLoading: false,
    },
    initialIsActive: false,
    isActive: false,
    cameraOfflineAlertDelayThresholdMinutes: null as number | null,

    showViewStreamHelpDialog: false,
    downloadingFrame: false,
    viewFrame: {
      show: false,
      cameraFrameUrl: "" as string,
      isLoading: false,
    },

    isSpotTrackingCamera: false,
    isLPRCamera: false,
    isLPRCameraStatusCheck: false,
    isDirectionDetectedByCamera: false,
    isEntryLPRCamera: false,
    isExitLPRCamera: false,
    selectedEntryCameraDirection: "reverse",
    selectedExitCameraDirection: "forward",

    lpr: {
      lprUrl: null as string | null,
      cameraTypes: [
        // { name: "Spotgenius", value: "spotgenius_lpr" },
        { name: "Hikvision", value: "hikvision_lpr" },
        { name: "Tagmaster", value: "tagmaster_lpr" },
        // { name: "Survision", value: "survision_lpr" },
        // { name: "Eagle Eye", value: "eagleeye_lpr" },
        { name: "Plate Recognizer", value: "platerecognizer_lpr" },
        { name: "Flock", value: "flock_lpr" },
      ],
      selectedCameraType: null as LprCameraType | null,
      directionItems: [
        {
          name: "Entry LPR Camera",
          value: LprCameraDirection.lpr_direction_entry,
        },
        {
          name: "Exit LPR Camera",
          value: LprCameraDirection.lpr_direction_exit,
        },
        {
          name: "Both Entry and Exit LPR Camera",
          value: "lpr_direction_both",
        },
      ],
      selectedDirection:
        LprCameraDirection.lpr_direction_entry as LprCameraDirection,
      detectionDirectionItems: [
        {
          name: "Front Plate",
          value: "forward",
        },
        {
          name: "Back Plate",
          value: "reverse",
        },
      ],
      selectedDetectionDirection: "both",
      cropAnprMatchingImageUsingInference: false,

      lprBasedVehicleCounting: {
        isEnabled: false,
        isLotBoundaryLprCamera: true,
        zoneId: null as number | null,
        lprCounterZones: [] as Array<ParkingZone>,
      },
      flockLprExternalNetworkId: null as string | null,
      flockLprExternalDeviceId: null as string | null,
      ignoreVehicleTypeThreshold: 0.15 as number | null,
      ignoreVehicleOrientationThreshold: DEFAULT_THRESHOLD as number | null,
      ignoreUnknownRecords: true, // currently used only for flock
      enableIgnoreDirectionThreshold: false,
      ignoreDirectionThresholdStart: null as number | null,
      ignoreDirectionThresholdEnd: null as number | null,
      ignoreLicensePlateThreshold: DEFAULT_THRESHOLD as number | null,
    },

    adjacentZone: {
      isEnabled: false,
      untrackedAdjacentZones: [] as Array<ParkingZone>,
      zoneId: null as number | null,
    },

    onboarding: {
      isLoadingLiveview: false,
      liveViewFrame: null as string | null,
      isUploadingReferenceImage: false,
      cameraReferenceFrameUrl: null as string | null,

      showCameraMap: false,
      loadingCameraMap: false,
      autoRefreshLiveView: false,
      loadingLiveView: false,
      liveViewUpdatedAt: null as Date | null,
      disable: false,
    },

    details: {
      overview: {
        modelNumber: null as string | null,
        manufacturer: null as string | null,
      },
      networking: {
        ipAddress: null as string | null,
        macAddress: null as string | null,
        isNvrConnected: false,
        nvrChannel: null as number | null,
        nvrPort: null as number | null,
        rtspPort: null as number | null,
        httpPort: null as number | null,
        username: null as string | null,
        password: null as string | null,
        showPassword: false,
        isWifiConnected: false,
        accessPointName: null as string | null,
      },
      videoSettings: {
        resolutionWidth: null as number | null,
        resolutionHeight: null as number | null,
        resolution: null as string | null,
        frameRate: null as number | null,
        bitrateType: null as string | null,
        maxBitrate: null as number | null,
        encodingMethod: null as string | null,
        iframeInterval: null as number | null,
      },
    },

    newCamera: {
      show: false,
      camera: null as Camera | null,
      receiveDataEndpoint: "",
      heartbeatEndpoint: "",
    },

    infoPopup: {
      show: false,
      title: "",
      message: "",
    },

    enableSeamlessOnboardingFeatureFlag:
      process.env.VUE_APP_ENABLE_SEAMLESS_ONBOARDING == 1,

    IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED:
      process.env.VUE_APP_IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED,

    waitingForCameraToBeDisabled: false,
  }),

  computed: {
    ...mapGetters("user", [
      "isSuperAdmin",
      "isTechnician",
      "hasAccessLevelCameraEditing",
    ]),
    ...mapGetters("data", ["getCurrentParkingLotData"]),
    isExternalLpr(): boolean {
      return (
        this.lpr.selectedCameraType != null &&
        this.lpr.selectedCameraType != "spotgenius_lpr"
      );
    },
    allCameraFieldsValidate(): string {
      let all_errors = "";
      if (!this.name) {
        all_errors += "*Name is required <br>";
      }
      if (
        this.getCurrentParkingLotData &&
        this.getCurrentParkingLotData.is_lpr_feature_enabled &&
        !this.isSpotTrackingCamera &&
        !this.isLPRCamera
      ) {
        all_errors +=
          "*Either Spot Tracking or LPR Camera should be selected <br>";
      } else if (
        this.getCurrentParkingLotData &&
        !this.getCurrentParkingLotData.is_lpr_feature_enabled &&
        !this.isSpotTrackingCamera
      ) {
        all_errors += "*Spot Tracking should be selected <br>";
      }
      if (this.isSpotTrackingCamera) {
        if (!this.streamUrl) {
          all_errors += "*Stream URL is required <br>";
        }
        if (!this.inferenceProcessingMethod) {
          all_errors += "*Inference Processing Method is required <br>";
        }
        // This capacity check can only be done on the backend
        // if (
        //   this.inferenceProcessingMethod == "cloud" &&
        //   !this.servers.selectedId
        // ) {
        //   all_errors +=
        //     "*Server is not available, please retry again, or contact Admin <br>";
        // }
        if (
          this.inferenceProcessingMethod == "edge_device" &&
          !this.edgeDevices.selectedId
        ) {
          all_errors += "*Edge Device Name must be selected <br>";
        }
      }
      if (this.isLPRCamera) {
        if (
          this.lpr.selectedCameraType === "platerecognizer_lpr" &&
          !this.lprStreamUrl
        ) {
          all_errors += "*Stream URL is required <br>";
        }
        if (!this.lpr.selectedCameraType) {
          all_errors += "*LPR Camera Brand must be selected <br>";
        } else {
          if (
            this.isLPRCameraStatusCheck &&
            (this.lpr.selectedCameraType == "hikvision_lpr" ||
              this.lpr.selectedCameraType == "platerecognizer_lpr") &&
            !this.lpr.lprUrl
          ) {
            all_errors += "*LPR LIVE Status check URL is required <br>";
          }
          if (this.lpr.selectedCameraType == "platerecognizer_lpr") {
            if (
              this.lpr.ignoreVehicleTypeThreshold == null ||
              (typeof this.lpr.ignoreVehicleTypeThreshold === "string" &&
                this.lpr.ignoreVehicleTypeThreshold === "") ||
              this.lpr.ignoreVehicleTypeThreshold < 0 ||
              this.lpr.ignoreVehicleTypeThreshold > 1
            ) {
              all_errors +=
                "*Ignore Vehicle Type Threshold must be between 0 and 1 <br>";
            }
            if (this.lpr.enableIgnoreDirectionThreshold) {
              if (
                this.lpr.ignoreDirectionThresholdStart &&
                this.lpr.ignoreDirectionThresholdEnd
              ) {
                if (
                  this.lpr.ignoreDirectionThresholdStart < 0 ||
                  this.lpr.ignoreDirectionThresholdEnd > 360
                ) {
                  all_errors +=
                    "*Ignore Vehicle Direction Threshold range must be between 0 and 360 <br>";
                }
              } else {
                all_errors +=
                  "*Ignore Vehicle Direction Threshold range must be between 0 and 360 <br>";
              }
            }
            if (
              this.lpr.ignoreVehicleOrientationThreshold == null ||
              (typeof this.lpr.ignoreVehicleOrientationThreshold === "string" &&
                this.lpr.ignoreVehicleOrientationThreshold === "") ||
              this.lpr.ignoreVehicleOrientationThreshold < 0 ||
              this.lpr.ignoreVehicleOrientationThreshold > 1
            ) {
              all_errors +=
                "*Ignore Vehicle Orientation Threshold must be between 0 and 1 <br>";
            }
          }
          if (this.lpr.selectedCameraType == "flock_lpr") {
            if (
              !this.lpr.flockLprExternalNetworkId ||
              this.lpr.flockLprExternalNetworkId == null ||
              this.lpr.flockLprExternalNetworkId.trim() == ""
            ) {
              all_errors += "*Flock LPR External Network ID must be set <br>";
            }
            if (
              !this.lpr.flockLprExternalDeviceId ||
              this.lpr.flockLprExternalDeviceId == null ||
              this.lpr.flockLprExternalDeviceId.trim() == ""
            ) {
              all_errors += "*Flock LPR External Device ID must be set <br>";
            }
            if (
              this.lpr.ignoreLicensePlateThreshold &&
              (this.lpr.ignoreLicensePlateThreshold < 0 ||
                this.lpr.ignoreLicensePlateThreshold > 1)
            ) {
              all_errors +=
                "*License Plate Confidence Threshold must be between 0 and 1 <br>";
            }
            if (
              this.lpr.ignoreVehicleOrientationThreshold &&
              (this.lpr.ignoreVehicleOrientationThreshold < 0 ||
                this.lpr.ignoreVehicleOrientationThreshold > 1)
            ) {
              all_errors +=
                "*Direction Confidence Threshold must be between 0 and 1 <br>";
            }
          }
          if (!this.isEntryLPRCamera && !this.isExitLPRCamera) {
            all_errors +=
              "*Either Entry or Exit LPR Camera should be selected <br>";
          }
          if (this.isDirectionDetectedByCamera) {
            if (this.isEntryLPRCamera && !this.selectedEntryCameraDirection) {
              all_errors += "*Entry LPR Camera Direction must be selected <br>";
            }
            if (this.isExitLPRCamera && !this.selectedExitCameraDirection) {
              all_errors += "*Exit LPR Camera Direction must be selected <br>";
            }
          }
          if (
            this.lprInferenceProcessingMethod == "edge_device" &&
            !this.edgeDevices.selectedLprId
          ) {
            all_errors +=
              "*Edge Device Name must be selected for LPR Camera <br>";
          }
        }
      }
      return all_errors;
    },
    instructionsLink(): string {
      if (this.lpr.selectedCameraType == "hikvision_lpr") {
        return "https://docs.spotgenius.com/1.0/using-spotgenius/lpr-camera-configuration/configuring-endpoint-url/configure-hikvision-endpoint-url";
      } else if (this.lpr.selectedCameraType == "tagmaster_lpr") {
        return "https://docs.spotgenius.com/1.0/using-spotgenius/lpr-camera-configuration/configuring-endpoint-url/configure-tagmaster-endpoint-url";
      } else if (this.lpr.selectedCameraType == "platerecognizer_lpr") {
        return "https://docs.spotgenius.com/1.0/using-spotgenius/lpr-camera-configuration/configuring-endpoint-url/configure-plate-recognizer-endpoint-url";
      } else if (this.lpr.selectedCameraType == "flock_lpr") {
        return "https://docs.spotgenius.com/1.0/using-spotgenius/lpr-camera-configuration/configuring-endpoint-url/configure-flock-endpoint-url";
      }
      return "";
    },
  },

  mounted() {
    this.initFormWithCameraDetails(this.existingCameraDetails);
    this.checkIfCameraMapExists();
    window.addEventListener("resize", this.updateDimensions);
    setTimeout(() => {
      this.updateDimensions();
    }, 3000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateDimensions);
  },

  methods: {
    async checkIfCameraMapExists() {
      if (!this.existingCameraDetails) return;
      let cameraMapData = await api.getCameraMapDetails(
        this.parkingLotId,
        this.existingCameraDetails.id
      );
      if (cameraMapData) {
        if (
          (cameraMapData?.parking_spots == null ||
            cameraMapData?.parking_spots.length <= 0) &&
          (cameraMapData?.parking_spots_ev_chargers == null ||
            cameraMapData?.parking_spots_ev_chargers.length <= 0) &&
          (cameraMapData?.special_areas == null ||
            cameraMapData?.special_areas.length <= 0) &&
          (cameraMapData?.count_vehicles_only_in_roi == null ||
            cameraMapData?.count_vehicles_only_in_roi?.poly == null) &&
          (cameraMapData?.count_vehicles_crossing_line_points == null ||
            cameraMapData?.count_vehicles_crossing_line_points?.line == null) &&
          (cameraMapData?.parking_lanes_cameras == null ||
            cameraMapData?.parking_lanes_cameras.length <= 0) &&
          (cameraMapData?.signage_objects == null ||
            cameraMapData?.signage_objects.length <= 0)
        ) {
          this.onboarding.disable = true;
        }
      } else {
        this.onboarding.disable = true;
      }
    },
    async initFormWithCameraDetails(cameraDetails: Camera) {
      if (cameraDetails) {
        this.isLoadingCameraData = true;
      }

      if (this.flockNetworkId) {
        this.lpr.flockLprExternalNetworkId = this.flockNetworkId;
      }

      if (cameraDetails) {
        console.log(
          "Editing existing camera",
          cameraDetails.id,
          cameraDetails.name
        );
        this.cameraId = cameraDetails.id;
        this.name = cameraDetails.name;
        this.streamUrl = cameraDetails.stream_url;
        this.lprStreamUrl = cameraDetails.lpr_stream_url;
        this.publicStreamUrl = cameraDetails.public_stream_url || "";
        this.comment = cameraDetails.comment;
        const { coordinates } =
          typeof cameraDetails.gps_coordinates === "string"
            ? JSON.parse(cameraDetails.gps_coordinates)
            : cameraDetails.gps_coordinates;
        this.lat = coordinates[1];
        this.lng = coordinates[0];
        this.isCameraGarbledImageDetectionFeatureEnabled =
          cameraDetails.is_camera_garbled_image_detection_feature_enabled;

        if (this.streamUrl) {
          this.isSpotTrackingCamera = true;
        }
        if (cameraDetails.is_lpr_camera_type) {
          this.isLPRCamera = true;

          this.isLPRCameraStatusCheck =
            cameraDetails.is_lpr_status_check_enabled;
          this.isDirectionDetectedByCamera =
            cameraDetails.is_direction_detected_from_lpr;
          if (cameraDetails.lpr_url) {
            this.lpr.lprUrl = cameraDetails.lpr_url;
          }
          if (cameraDetails.lpr_direction) {
            if (
              cameraDetails.lpr_direction ==
              LprCameraDirection.lpr_direction_entry
            ) {
              this.isEntryLPRCamera = true;
            } else if (
              cameraDetails.lpr_direction ==
              LprCameraDirection.lpr_direction_exit
            ) {
              this.isExitLPRCamera = true;
            }
          } else {
            this.isEntryLPRCamera = true;
            this.isExitLPRCamera = true;
          }
          if (cameraDetails.save_lpr_events_only_for_vehicle_facing_direction) {
            this.lpr.selectedDetectionDirection =
              cameraDetails.save_lpr_events_only_for_vehicle_facing_direction;
            this.selectedEntryCameraDirection =
              cameraDetails.save_lpr_events_only_for_vehicle_facing_direction;
            this.selectedExitCameraDirection =
              cameraDetails.save_exit_lpr_events_only_for_vehicle_facing_direction;

            if (this.selectedEntryCameraDirection == "both") {
              if (this.selectedExitCameraDirection == "reverse") {
                this.selectedEntryCameraDirection = "forward";
              } else {
                this.selectedEntryCameraDirection = "reverse";
              }
            } else if (this.selectedExitCameraDirection == "both") {
              if (this.selectedEntryCameraDirection == "reverse") {
                this.selectedExitCameraDirection = "forward";
              } else {
                this.selectedExitCameraDirection = "reverse";
              }
            } else {
              this.selectedEntryCameraDirection =
                cameraDetails.save_lpr_events_only_for_vehicle_facing_direction;
              this.selectedExitCameraDirection =
                cameraDetails.save_exit_lpr_events_only_for_vehicle_facing_direction;
            }
          }

          this.lpr.lprUrl = cameraDetails.lpr_url;
          this.lpr.selectedCameraType = cameraDetails.is_lpr_camera_type;
          this.lpr.selectedDirection =
            cameraDetails.lpr_direction ||
            LprCameraDirection.lpr_direction_entry;
          this.lpr.selectedDetectionDirection =
            cameraDetails.save_lpr_events_only_for_vehicle_facing_direction;
          this.lpr.cropAnprMatchingImageUsingInference =
            cameraDetails.crop_anpr_matching_image_using_inference;

          this.onLPRCameraDirectionChange("entry");

          // LPR Based Vehicle Counting
          this.lpr.lprBasedVehicleCounting.isEnabled =
            cameraDetails.lpr_zone_id != null && this.isLPRCamera;
          this.lpr.lprBasedVehicleCounting.zoneId =
            this.isLPRCamera && !this.isSpotTrackingCamera
              ? cameraDetails.untracked_zone_id
              : cameraDetails.lpr_zone_id;
          this.lpr.lprBasedVehicleCounting.isLotBoundaryLprCamera =
            cameraDetails.is_lot_boundary_lpr_camera;
        }
        if (cameraDetails.flock_lpr_external_network_id) {
          this.lpr.flockLprExternalNetworkId =
            cameraDetails.flock_lpr_external_network_id;
        }
        this.lpr.flockLprExternalDeviceId =
          cameraDetails.flock_lpr_external_device_id;

        // Adjacent Zone
        this.adjacentZone.isEnabled = cameraDetails.adjacent_zone_id != null;
        this.adjacentZone.zoneId = cameraDetails.adjacent_zone_id;

        this.lpr.ignoreUnknownRecords =
          cameraDetails.lpr_ignore_unknown_records;

        this.lpr.ignoreVehicleTypeThreshold =
          cameraDetails.lpr_vehicle_type_threshold;
        this.lpr.ignoreVehicleOrientationThreshold =
          cameraDetails.lpr_vehicle_orientation_threshold;

        this.lpr.ignoreDirectionThresholdStart =
          cameraDetails.lpr_vehicle_direction_threshold_start;
        this.lpr.ignoreDirectionThresholdEnd =
          cameraDetails.lpr_vehicle_direction_threshold_end;

        this.lpr.ignoreLicensePlateThreshold =
          cameraDetails.lpr_license_plate_confidence_threshold;

        if (
          this.lpr.ignoreDirectionThresholdStart != null &&
          this.lpr.ignoreDirectionThresholdEnd != null
        ) {
          this.lpr.enableIgnoreDirectionThreshold = true;
        } else {
          this.lpr.enableIgnoreDirectionThreshold = false;
        }

        this.fetchLprZones();
        this.setLPREndpoints(cameraDetails);

        // get camera details for the camera
        if (this.isSuperAdmin || this.isTechnician) {
          let camera_details = await api.getCameraDetails(
            this.parkingLotId,
            this.cameraId
          );
          if (camera_details) {
            this.details.overview.modelNumber = camera_details.model_number;
            this.details.overview.manufacturer = camera_details.manufacturer;
            this.details.networking.ipAddress = camera_details.ip_address;
            this.details.networking.macAddress = camera_details.mac_address;
            this.details.networking.nvrChannel = camera_details.nvr_channel;
            this.details.networking.nvrPort = camera_details.nvr_port;
            this.details.networking.rtspPort = camera_details.rtsp_port;
            this.details.networking.httpPort = camera_details.http_port;
            this.details.networking.username = camera_details.admin_username;
            this.details.networking.password = camera_details.admin_password;

            this.details.networking.isWifiConnected =
              camera_details.is_wifi_connected || false;
            this.details.networking.accessPointName =
              camera_details.wifi_access_point_name;

            this.details.videoSettings.resolution = camera_details.resolution;
            this.details.videoSettings.frameRate = camera_details.frame_rate;
            this.details.videoSettings.bitrateType =
              camera_details.bitrate_type;
            this.details.videoSettings.maxBitrate = camera_details.max_bitrate;
            this.details.videoSettings.encodingMethod =
              camera_details.encoding_method;
            this.details.videoSettings.iframeInterval =
              camera_details.iframe_interval;
          }
        }

        if (!this.isSuperAdmin && !this.isTechnician) {
          this.refreshOnboardingLiveView();
        }
      }

      // Load Servers list for servers dropdown
      if (this.isSuperAdmin) {
        this.servers.isLoading = true;
        const servers = await api.getAllServers();
        if (servers) {
          let runningServers = servers.filter((s) => s.is_running === true);
          this.servers.items = runningServers;
        }
        this.servers.isLoading = false;
      }

      // Load Edge devices list
      if (this.isSuperAdmin) {
        this.edgeDevices.isLoading = true;
        const edgeDevices = await api.getAllEdgeDevices(this.parkingLotId);
        if (edgeDevices) {
          this.edgeDevices.items = edgeDevices;

          if (this.edgeDevices.items.length > 0) {
            this.inferenceProcessingMethodItems = ["cloud", "edge_device"];
            this.edgeDevices.selectedId = this.edgeDevices.items[0].id;
            this.edgeDevices.selectedLprId = this.edgeDevices.items[0].id;
            if (this.edgeDevices.items.length === 1) {
              this.showEdgeDeviceSelect = false;
            } else {
              this.showEdgeDeviceSelect = true;
            }
          }
        }
        this.edgeDevices.isLoading = false;
      }

      if (cameraDetails) {
        this.inferenceProcessingMethod =
          cameraDetails.is_inference_processing_method;
        if (cameraDetails.server_id) {
          this.servers.selectedId = cameraDetails.server_id;
        }
        if (cameraDetails.edge_device_id) {
          this.edgeDevices.selectedId = cameraDetails.edge_device_id;
        }
        if (cameraDetails.lpr_edge_device_id) {
          this.edgeDevices.selectedLprId = cameraDetails.lpr_edge_device_id;
          this.lprInferenceProcessingMethod = "edge_device";
        }

        this.isActive = cameraDetails.is_active;
        this.initialIsActive = cameraDetails.is_active;
        this.cameraOfflineAlertDelayThresholdMinutes =
          cameraDetails.camera_offline_alert_delay_threshold_minutes;

        // Init Onboarding Tab
        this.onboarding.cameraReferenceFrameUrl =
          cameraDetails.reference_frame_path_url;
      }

      this.isLoadingCameraData = false;
    },

    async fetchLprZones() {
      this.lpr.lprBasedVehicleCounting.lprCounterZones = [];
      if (this.cameraId == null) {
        return;
      }
      let parkingLotDetails = await api.getParkingLot(this.parkingLotId);
      this.lpr.lprBasedVehicleCounting.lprCounterZones =
        parkingLotDetails?.parking_zones.filter((z) =>
          ["lpr_counter_zone", "normal_spots_zone"].includes(z.zone_type)
        ) || [];

      // Allow only line counter and untracked zones
      this.adjacentZone.untrackedAdjacentZones =
        parkingLotDetails?.parking_zones.filter((z) =>
          ["line_counter_zone", "lpr_counter_zone"].includes(z.zone_type)
        ) || [];
    },

    refreshLprLogs() {
      this.$emit("refresh-lpr-logs", this.existingCameraDetails.id);
    },

    copyToClipboardStreamURL() {
      navigator.clipboard.writeText(this.streamUrl);
      this.$dialog.message.info("Camera Stream URL copied to clipboard", {
        position: "top-right",
        timeout: 3000,
      });
    },

    async submitForm() {
      let cameraData: CameraCreate | CameraUpdate = {
        name: this.name,
        level_id: this.existingCameraDetails?.level_id || null,
        gps_coordinates: {
          type: "Point",
          coordinates: [this.lng, this.lat],
        } as Point,
        stream_url: this.isSpotTrackingCamera ? this.streamUrl : "",
        lpr_stream_url: this.isLPRCamera ? this.lprStreamUrl : null,
        public_stream_url: this.publicStreamUrl,
        parking_lot_id: this.parkingLotId,
        is_inference_processing_method: this.isSpotTrackingCamera
          ? this.inferenceProcessingMethod
          : this.lprInferenceProcessingMethod,
        server_id:
          this.isSpotTrackingCamera && this.inferenceProcessingMethod == "cloud"
            ? this.servers.selectedId
            : null,
        edge_device_id:
          this.isSpotTrackingCamera &&
          this.inferenceProcessingMethod == "edge_device"
            ? this.edgeDevices.selectedId
            : null,
        lpr_edge_device_id:
          this.isLPRCamera &&
          this.lpr.selectedCameraType != null &&
          this.lpr.selectedCameraType == "platerecognizer_lpr" &&
          this.lprInferenceProcessingMethod == "edge_device"
            ? this.edgeDevices.selectedLprId
            : null,
        camera_offline_alert_delay_threshold_minutes:
          this.cameraOfflineAlertDelayThresholdMinutes,
        comment: this.comment != "" ? this.comment : null,
        is_lpr_camera_type: this.isLPRCamera
          ? this.lpr.selectedCameraType
          : null,
        is_camera_garbled_image_detection_feature_enabled:
          this.isCameraGarbledImageDetectionFeatureEnabled,
        fov_direction: this.existingCameraDetails
          ? this.existingCameraDetails.fov_direction
          : null,
        lpr_direction:
          this.isEntryLPRCamera && this.isExitLPRCamera
            ? null
            : this.isEntryLPRCamera
            ? LprCameraDirection.lpr_direction_entry
            : this.isExitLPRCamera
            ? LprCameraDirection.lpr_direction_exit
            : null,
        lpr_url: this.lpr.lprUrl,
        is_lpr_status_check_enabled: this.isLPRCameraStatusCheck,
        is_direction_detected_from_lpr: this.isDirectionDetectedByCamera,
        save_lpr_events_only_for_vehicle_facing_direction: this
          .isDirectionDetectedByCamera
          ? this.selectedEntryCameraDirection
          : "both",
        save_exit_lpr_events_only_for_vehicle_facing_direction: this
          .isDirectionDetectedByCamera
          ? this.selectedExitCameraDirection
          : "both",
        crop_anpr_matching_image_using_inference:
          this.lpr.cropAnprMatchingImageUsingInference,

        // Zone ID for LPR based vehicle counting
        untracked_zone_id:
          this.isLPRCamera && !this.isSpotTrackingCamera
            ? this.lpr.lprBasedVehicleCounting.zoneId
            : this.existingCameraDetails?.untracked_zone_id || null,
        is_lot_boundary_lpr_camera: this.isLPRCamera
          ? this.lpr.lprBasedVehicleCounting.isLotBoundaryLprCamera
          : false,
        adjacent_zone_id: this.adjacentZone.zoneId,
        lpr_zone_id: this.lpr.lprBasedVehicleCounting.zoneId,
        lpr_log: this.existingCameraDetails
          ? this.existingCameraDetails.lpr_log
          : null,
        flock_lpr_external_network_id: this.lpr.flockLprExternalNetworkId,
        flock_lpr_external_device_id: this.lpr.flockLprExternalDeviceId,
        lpr_ignore_unknown_records: this.lpr.ignoreUnknownRecords,
        lpr_vehicle_type_threshold: this.lpr.ignoreVehicleTypeThreshold,
        lpr_vehicle_orientation_threshold:
          this.lpr.ignoreVehicleOrientationThreshold,
        lpr_vehicle_direction_threshold_start:
          this.lpr.ignoreDirectionThresholdStart,
        lpr_vehicle_direction_threshold_end:
          this.lpr.ignoreDirectionThresholdEnd,
        lpr_license_plate_confidence_threshold:
          this.lpr.ignoreLicensePlateThreshold,
      };

      console.log("submitting form data", cameraData);
      if (this.cameraId == null) {
        this.createCamera(cameraData);
      } else {
        cameraData = {
          id: this.cameraId,
          is_active: this.isActive,
          ...cameraData,
        };
        this.updateCamera(cameraData);
      }
    },

    async createCamera(cameraData: CameraCreate) {
      try {
        let camera = await api.createCamera(cameraData);
        if (camera) {
          console.log("Saved camera with response", camera);
          this.$dialog.message.info("New camera created successfully", {
            position: "top-right",
            timeout: 3000,
          });
          setTimeout(() => {
            this.$emit("close-form");
            this.$emit("refresh-data");

            setTimeout(() => {
              if (camera && camera.is_lpr_camera_type) {
                this.$emit("show-endpoints", camera);
              }
            }, 600);
          }, 400);
        } else {
          console.log("Failed to create new camera");
          this.$dialog.message.error("Error, unable to create new camera", {
            position: "top-right",
            timeout: 3000,
          });
        }
      } catch (error) {
        if (error.response.status !== 200) {
          this.$dialog.message.error(error.response.data.detail, {
            position: "top-right",
            timeout: 3000,
          });
          return;
        }
      }
    },

    async updateCamera(cameraData: CameraUpdate) {
      let camera = null;
      try {
        camera = await api.updateCamera(cameraData);
      } catch (error) {
        // When no AI server capacity to start processing camera
        if (error.response.status !== 200) {
          this.$dialog.message.error(error.response.data.detail, {
            position: "top-right",
            timeout: 3000,
          });
          return;
        }
      }

      if (camera && (await this.saveCameraDetails())) {
        console.log("Saved camera with response", camera);
        let waitTime = 0;
        // Wait for a few seconds before closing form if the camera has been disabled
        if (this.isActive === false && this.initialIsActive === true) {
          waitTime = process.env.VUE_APP_CAMERA_RESTART_WAIT_TIME_SECONDS;
          this.waitingForCameraToBeDisabled = true;
        }
        setTimeout(() => {
          this.$dialog.message.info("Camera details saved successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.$emit("close-form");
          this.$emit("refresh-data");
        }, waitTime * 1000);
      } else {
        console.log("Failed to save camera");
        this.$dialog.message.error("Error, unable to update camera details", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },

    async saveCameraDetails() {
      // save camera details
      if (this.parkingLotId && this.cameraId) {
        let cameraDetails: CameraDetails = {
          camera_id: this.cameraId,

          model_number: this.details.overview.modelNumber,
          manufacturer: this.details.overview.manufacturer,

          ip_address: this.details.networking.ipAddress,
          mac_address: this.details.networking.macAddress,
          is_nvr_connected: this.details.networking.isNvrConnected,
          nvr_channel: this.details.networking.isNvrConnected
            ? this.details.networking.nvrChannel
            : null,
          nvr_port: this.details.networking.nvrPort,
          rtsp_port: this.details.networking.rtspPort,
          http_port: this.details.networking.httpPort,
          admin_username: this.details.networking.username,
          admin_password: this.details.networking.password,

          is_wifi_connected: this.details.networking.isWifiConnected,
          wifi_access_point_name: this.details.networking.isWifiConnected
            ? this.details.networking.accessPointName
            : null,

          resolution: this.details.videoSettings.resolution,
          frame_rate: this.details.videoSettings.frameRate,
          bitrate_type: this.details.videoSettings.bitrateType,
          max_bitrate: this.details.videoSettings.maxBitrate,
          encoding_method: this.details.videoSettings.encodingMethod,
          iframe_interval: this.details.videoSettings.iframeInterval,
        };

        let camera = await api.saveCameraDetails(
          this.parkingLotId,
          this.cameraId,
          cameraDetails
        );
        if (camera) {
          return true;
        } else {
          console.log("Failed to save Camera Details");
          return false;
        }
      }
    },

    async downloadFrame() {
      if (!this.cameraId) return;
      this.downloadingFrame = true;
      try {
        const url = await api.downloadFrame(this.parkingLotId, this.cameraId);
        if (url) {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "frame.jpg");
          document.body.appendChild(link);
          link.click();
        } else {
          this.$dialog.message.error(
            "Read Error, unable to read frame camera.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.$dialog.message.warning(
            "Unstable camera stream connection, unable to read frame.<br> Please try again later.",
            {
              position: "top-right",
              timeout: 5000,
            }
          );
        }
      }
      this.downloadingFrame = false;
    },

    openStream() {
      navigator.clipboard.writeText(this.streamUrl);
      if (this.streamUrl.startsWith("http")) {
        // Only open the youtube streams automatically in a new tab.
        window.open(this.streamUrl, "_blank");
      } else {
        // Automatic opening of stream is disabled for rtsp, since it doesn't work
        // reliably on all browsers & systems. So we ask the user to launch VLC
        // themselves from the help popup dialog.
        // window.open(this.camera.stream_url);
        this.showViewStreamHelpDialog = true;
      }
    },

    onShowViewStreamHelpChanged(value: boolean) {
      this.showViewStreamHelpDialog = value;
    },

    async showCameraFrameDialog() {
      this.viewFrame.show = !this.viewFrame.show;
      this.viewFrame.isLoading = true;
      if (
        this.existingCameraDetails &&
        this.existingCameraDetails.parking_lot_id &&
        this.existingCameraDetails.id &&
        this.existingCameraDetails.name
      ) {
        try {
          let url = await api.downloadFrame(
            this.existingCameraDetails.parking_lot_id,
            this.existingCameraDetails.id
          );
          if (url) {
            if (this.viewFrame.show) {
              this.showImageInViewer(url);
            }
          } else {
            this.$dialog.message.error(
              `Read Error, unable to read from camera "${this.existingCameraDetails.name}"`,
              {
                position: "top-right",
                timeout: 3000,
              }
            );
            this.viewFrame.show = false;
            this.viewFrame.isLoading = false;
          }
        } catch (error) {
          if (error.response.status === 403) {
            this.$dialog.message.warning(
              "Unstable camera stream connection, unable to read frame.<br> Please try again later.",
              {
                position: "top-right",
                timeout: 5000,
              }
            );
            this.viewFrame.show = false;
            this.viewFrame.isLoading = false;
          }
        }
      }
      if (this.viewFrame.show) {
        this.viewFrame.isLoading = false;
        this.viewFrame.show = false;
      }
    },

    /**
     * Display image in given url inside a fullscreen zoomable/pannable image viewer.
     */
    showImageInViewer(url: string) {
      this.$viewerApi({
        images: [url],
      });
    },

    showCameraMapEditor() {
      this.closeForm();
      this.$emit("show-camera-map-editor");
    },

    resetForm() {
      this.tab = "overview";

      this.cameraId = null;
      this.name = "";
      this.streamUrl = "";
      this.publicStreamUrl = "";
      this.comment = null;
      this.lat = 0;
      this.lng = 0;
      this.inferenceProcessingMethod = "cloud";
      this.lprInferenceProcessingMethod = "cloud";
      this.servers.selectedId = null;
      this.edgeDevices.selectedId = null;
      this.isActive = false;
      this.initialIsActive = false;

      this.isSpotTrackingCamera = false;
      this.isLPRCamera = false;
      this.isLPRCameraStatusCheck = false;
      this.isDirectionDetectedByCamera = false;
      this.isEntryLPRCamera = false;
      this.isExitLPRCamera = false;
      this.selectedEntryCameraDirection = "reverse";
      this.selectedExitCameraDirection = "forward";

      (this.$refs.cameraFormElm as VForm).resetValidation();

      this.onboarding.isLoadingLiveview = false;
      this.onboarding.isUploadingReferenceImage = false;
      this.onboarding.cameraReferenceFrameUrl = null;
      this.onboarding.liveViewFrame = null;
      this.isCameraGarbledImageDetectionFeatureEnabled = true;

      this.lpr.selectedCameraType = null;
      this.lpr.selectedDirection = LprCameraDirection.lpr_direction_entry;
      this.lpr.lprUrl = null;
      this.lpr.selectedDetectionDirection = "both";
      this.lpr.cropAnprMatchingImageUsingInference = false;
      this.lpr.flockLprExternalDeviceId = null;
      this.lpr.flockLprExternalNetworkId = null;
      this.lpr.ignoreUnknownRecords = true;
      this.lpr.ignoreVehicleTypeThreshold = DEFAULT_THRESHOLD;
      this.lpr.ignoreVehicleOrientationThreshold = DEFAULT_THRESHOLD;
      this.lpr.ignoreDirectionThresholdStart = 0;
      this.lpr.ignoreDirectionThresholdEnd = 360;
      this.lpr.ignoreLicensePlateThreshold = DEFAULT_THRESHOLD;

      this.adjacentZone.untrackedAdjacentZones = [];
      this.adjacentZone.isEnabled = false;
      this.adjacentZone.zoneId = null;

      // reset camera details
      this.details.overview.modelNumber = null;
      this.details.overview.manufacturer = null;

      this.details.networking.ipAddress = null;
      this.details.networking.macAddress = null;
      this.details.networking.isNvrConnected = false;
      this.details.networking.nvrChannel = null;
      this.details.networking.nvrPort = null;
      this.details.networking.rtspPort = null;
      this.details.networking.httpPort = null;
      this.details.networking.username = null;
      this.details.networking.password = null;

      this.details.networking.isWifiConnected = false;
      this.details.networking.accessPointName = null;

      this.details.videoSettings.resolution = null;
      this.details.videoSettings.frameRate = null;
      this.details.videoSettings.bitrateType = null;
      this.details.videoSettings.maxBitrate = null;
      this.details.videoSettings.encodingMethod = null;
      this.details.videoSettings.iframeInterval = null;

      this.waitingForCameraToBeDisabled = false;
    },

    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },

    openAlert(alertID: number) {
      const routeData = this.$router.resolve({
        name: "Alerts",
        query: { alert_id: String(alertID) },
      });
      window.open(routeData.href, "_blank");
    },

    // Methods used by Onboarding Tab /////////////////////////////////////////

    async refreshOnboardingLiveView() {
      this.onboarding.isLoadingLiveview = true;
      if (
        this.existingCameraDetails &&
        this.existingCameraDetails.parking_lot_id &&
        this.existingCameraDetails.id &&
        this.existingCameraDetails.name
      ) {
        try {
          let url = await api.downloadFrame(
            this.existingCameraDetails.parking_lot_id,
            this.existingCameraDetails.id
          );
          if (url) {
            this.onboarding.liveViewFrame = url;
            this.onboarding.liveViewUpdatedAt = new Date();
          } else {
            this.$dialog.message.error(
              "Error, unable to fetch frame from camera.",
              {
                position: "top-right",
                timeout: 3000,
              }
            );
            this.viewFrame.show = false;
            this.viewFrame.isLoading = false;
          }
        } catch (error) {
          if (error.response.status === 403) {
            this.$dialog.message.warning(
              "Unstable camera stream connection, unable to read frame.<br> Please try again later.",
              {
                position: "top-right",
                timeout: 5000,
              }
            );
            this.viewFrame.show = false;
            this.viewFrame.isLoading = false;
          }
        }
      }
      this.onboarding.isLoadingLiveview = false;
      if (this.onboarding.autoRefreshLiveView) {
        this.refreshOnboardingLiveView();
      }
    },

    async onReferenceImageChanged(e: any) {
      if (e != null && e.target.files.length == 1 && this.cameraId) {
        let selectedImageFile = e.target.files[0];
        this.onboarding.isUploadingReferenceImage = true;
        let uploadedUrl = await api.uploadCameraReferenceImage(
          this.parkingLotId,
          this.cameraId,
          selectedImageFile
        );
        if (!uploadedUrl) {
          this.$dialog.message.error(
            "Upload Error. Reference Image Unchanged, please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
        this.onboarding.isUploadingReferenceImage = false;
        console.log("Uploaded to ", uploadedUrl);
        this.onboarding.cameraReferenceFrameUrl = uploadedUrl;
      } else {
        this.$dialog.message.error(
          "Reference Image Unchanged, please selected an image to replace existing image.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    updateDimensions() {
      const col: any = this.$refs.liveView;
      if (col) {
        if (col.offsetWidth <= 514) {
          this.stageWidth = col.offsetWidth;
        }
      }
    },

    checkLprUrl(value: string | null, lpr_camera_type: string | null) {
      if (
        lpr_camera_type !== "hikvision_lpr" &&
        lpr_camera_type !== "platerecognizer_lpr"
      ) {
        return true;
      }
      if (!value) return "Status check URL cannot be empty";
      try {
        const url = new URL(value);
        if (lpr_camera_type === "hikvision_lpr") {
          return (
            (url.protocol === "http:" || url.protocol === "https:") &&
            url.username.length > 0 &&
            url.password.length > 0 &&
            url.hostname.length > 0 &&
            url.port.length > 0
          );
        } else if (lpr_camera_type === "platerecognizer_lpr") {
          return (
            (url.protocol === "http:" || url.protocol === "https:") &&
            url.hostname.length > 0
          );
        }
        return true;
      } catch (error) {
        if (lpr_camera_type === "hikvision_lpr") {
          return "Please provide a valid Url in this format http://username:password@hostname:port";
        } else if (lpr_camera_type === "platerecognizer_lpr") {
          return "Please provide a valid Url in this format http://hostname";
        }
        return true;
      }
    },

    onZoneOnLprCameraChanged(zoneId: number) {
      // When an LPR camera does not have a zone assigned to it, then it must
      // be a boundary camera since an LPR camera can either be a boundary
      // camera or a internal zone camera.
      if (zoneId == null) {
        this.lpr.lprBasedVehicleCounting.isLotBoundaryLprCamera = true;
      }
    },

    onLPRCameraDirectionDetectionChange() {
      this.isEntryLPRCamera = true;
      this.isExitLPRCamera = false;
    },

    onLPRCameraDirectionChange(direction: string) {
      if (!this.isDirectionDetectedByCamera) {
        if (direction == "entry") {
          if (this.isEntryLPRCamera) {
            this.isExitLPRCamera = false;
          } else {
            this.isExitLPRCamera = true;
          }
        } else {
          if (this.isExitLPRCamera) {
            this.isEntryLPRCamera = false;
          } else {
            this.isEntryLPRCamera = true;
          }
        }
      }
      this.onDetectionDirectionChange(direction);
    },

    onLPRCameraDirectionThresholdChange() {
      if (this.lpr.enableIgnoreDirectionThreshold) {
        this.lpr.ignoreDirectionThresholdStart = 5;
        this.lpr.ignoreDirectionThresholdEnd = 175;
      } else {
        this.lpr.ignoreDirectionThresholdStart = null;
        this.lpr.ignoreDirectionThresholdEnd = null;
      }
    },

    onDetectionDirectionChange(direction: string) {
      if (direction == "entry") {
        if (this.selectedEntryCameraDirection == "forward") {
          this.selectedExitCameraDirection = "reverse";
        } else {
          this.selectedExitCameraDirection = "forward";
        }
      } else {
        if (this.selectedExitCameraDirection == "forward") {
          this.selectedEntryCameraDirection = "reverse";
        } else {
          this.selectedEntryCameraDirection = "forward";
        }
      }
    },

    copyToClipboard(val: string) {
      navigator.clipboard.writeText(val);
    },

    setLPREndpoints(camera: Camera) {
      if (this.lpr.selectedCameraType == "flock_lpr") {
        this.lpr.ignoreVehicleOrientationThreshold = DEFAULT_FLOCK_THRESHOLD;
        this.lpr.ignoreLicensePlateThreshold = DEFAULT_FLOCK_THRESHOLD;
      } else {
        this.lpr.ignoreVehicleOrientationThreshold = DEFAULT_THRESHOLD;
        this.lpr.ignoreLicensePlateThreshold = DEFAULT_THRESHOLD;
      }

      if (!camera) return;
      this.newCamera.show = true;
      this.newCamera.camera = camera;
      if (this.lpr.selectedCameraType == "flock_lpr") {
        this.newCamera.receiveDataEndpoint = `${process.env.VUE_APP_API_URL}/lpr/flock/lots/${camera.parking_lot_id}`;
      } else {
        this.newCamera.receiveDataEndpoint = `${process.env.VUE_APP_API_URL}/lpr/receive_data/lot_${camera.parking_lot_id}/camera_${camera.id}`;
      }
      this.newCamera.heartbeatEndpoint = `${process.env.VUE_APP_API_URL}/lpr/heartbeat/lot_${camera.parking_lot_id}/camera_${camera.id}`;
    },

    showInfoPopup(title: string, message: string) {
      this.infoPopup.title = title;
      this.infoPopup.message = message;
      this.infoPopup.show = true;
    },

    lastUpdatedAt() {
      if (this.onboarding.liveViewUpdatedAt) {
        let dayObj = dayjs(this.onboarding.liveViewUpdatedAt);
        const selectedTimezone = localStorage.getItem("selected_timezone");
        if (selectedTimezone) {
          dayObj = dayjs(this.onboarding.liveViewUpdatedAt).tz(
            selectedTimezone
          );
        }
        const selectedTimeFormat = localStorage.getItem("time_format_option");
        let formattedDate = dayObj.format("h:mm:ss A");
        if (selectedTimeFormat === "24_hr") {
          formattedDate = dayObj.format("HH:mm:ss");
        }

        let tz_short = "";
        if (selectedTimezone) {
          const locale = navigator.language ? navigator.language : "en-US";
          let timezone_short = new Intl.DateTimeFormat(locale, {
            timeZone: selectedTimezone,
            timeZoneName: "long",
          })
            .formatToParts(new Date())
            .find((part) => part.type === "timeZoneName")?.value;
          if (timezone_short) {
            // abbreviate text
            timezone_short = timezone_short
              .split(" ")
              .map((word) => word[0])
              .join("");
            tz_short = `${timezone_short}`;
          } else {
            tz_short = dayjs().tz(selectedTimezone).format("z");
          }
        }

        return formattedDate + ` ${tz_short}`;
      }
      return "";
    },
  },

  watch: {
    existingCameraDetails(cameraDetails) {
      if (cameraDetails) {
        this.initFormWithCameraDetails(cameraDetails);
      }
    },
    needsInit(show) {
      if (show) this.initFormWithCameraDetails(this.existingCameraDetails);
      else this.closeForm();
    },
    tab(currentTab) {
      if (currentTab === "onboarding") {
        this.refreshOnboardingLiveView();
      }
    },
    /**
     * Make field value null instead of empty string since the data type of this
     * field is either number or null.
     */
    cameraOfflineAlertDelayThresholdMinutes(newVal) {
      if (newVal === "") {
        this.cameraOfflineAlertDelayThresholdMinutes = null;
      }
    },
    "viewFrame.show"(showingFrame) {
      if (!showingFrame) {
        this.viewFrame.isLoading = false;
        this.viewFrame.cameraFrameUrl = "";
        this.$emit("show-loader", 2);
      }
    },
  },
});
