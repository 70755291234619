











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import dayjs, { Dayjs } from "dayjs";

import {
  VehicleParkingUsageTrackingAssociationDetails,
  VehicleParkingUsageTrackingRecordIgnored,
  LicensePlateRecognitionHistory,
  ParkingHistory,
} from "@/api/models";
import api from "@/api/api";
import { ParkingLotBasic } from "@/api/models";
import {
  formatDurationSeconds,
  formatDurationShowSeconds,
} from "@/libs/dateUtils";
import TimestampEditField from "@/components/TimestampEditField.vue";

interface CleanImageUrl {
  id: number;
  url: string;
}

export default Vue.extend({
  name: "VehicleParkingUsageRecordDetails",

  props: {
    showNextPrevControls: {
      type: Boolean,
      required: false,
      default: false,
    },
    recordId: {
      type: Number,
      required: true,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    TimestampEditField,
  },

  data() {
    return {
      showLprEntryImage: false,
      showDetectionEntryImage: false,
      showVehicleEntryImage: false,
      showLprExitImage: false,
      showVehicleExitImage: false,
      showDetectionExitImage: false,

      isLoading: false,
      isEditing: false,
      images: [] as Array<string>,
      imagesShowIndexes: {
        entry_detection: -1,
        entry_vehicle: -1,
        entry_lpr: -1,
        exit_detection: -1,
        exit_vehicle: -1,
        exit_lpr: -1,
        parking: [] as Array<number>,
      },
      recordDetails:
        null as VehicleParkingUsageTrackingAssociationDetails | null,
      parkingLotInfo: null as ParkingLotBasic | null,

      parkingHistoryCleanImages: [] as Array<CleanImageUrl>,
    };
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin"]),
    parkingHistoryCategoryType(): string {
      if (
        this.recordDetails &&
        this.recordDetails?.parking_history_records &&
        this.recordDetails?.parking_history_records[0].parking_zone_id
      ) {
        return "Parking Zones";
      } else if (
        this.recordDetails &&
        this.recordDetails?.parking_history_records &&
        this.recordDetails?.parking_history_records[0].special_area_id
      ) {
        return "Special Areas";
      }
      return "Parking Spots";
    },
    parkingHistorySpots(): string {
      let idsNames = [] as Array<string>;
      if (this.recordDetails?.parking_history_records) {
        for (const h of this.recordDetails?.parking_history_records) {
          if (h.name && !idsNames.includes(h.name)) {
            idsNames.push(h.name);
          } else if (
            h.parking_zone_id &&
            !idsNames.includes(h.parking_zone_id.toString())
          ) {
            idsNames.push(h.parking_zone_id.toString());
          } else if (
            h.special_area_id &&
            !idsNames.includes(h.special_area_id.toString())
          ) {
            idsNames.push(h.special_area_id.toString());
          }
        }
      }
      return idsNames.join(", ");
    },

    showAnprFields(): boolean {
      if (this.isSuperAdmin && this.parkingLotInfo?.is_anpr_feature_enabled) {
        return true;
      }

      // For other user types
      if (
        this.parkingLotInfo?.is_anpr_feature_enabled &&
        this.parkingLotInfo?.is_anpr_feature_visible_to_customers
      ) {
        return true;
      }

      return false;
    },

    licensePlateNotMatching(): boolean {
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_entry_record.license_plate_detected &&
        this.recordDetails.lpr_exit_record.license_plate_detected &&
        this.recordDetails.lpr_entry_record.license_plate_detected !=
          this.recordDetails.lpr_exit_record.license_plate_detected
      ) {
        return true;
      }
      if (
        this.recordDetails &&
        this.recordDetails.merged_parking_timeline_records &&
        this.recordDetails.merged_parking_timeline_records.length > 0
      ) {
        for (const record of this.recordDetails
          .merged_parking_timeline_records) {
          const record_license_plate_detected = record.event_data
            ? (record.event_data as LicensePlateRecognitionHistory)
                .license_plate_detected
            : null;
          if (
            record.event_type == "zone_lpr_event" &&
            record_license_plate_detected &&
            ((this.recordDetails.lpr_entry_record &&
              record_license_plate_detected !=
                this.recordDetails.lpr_entry_record.license_plate_detected) ||
              (this.recordDetails.lpr_exit_record &&
                record_license_plate_detected !=
                  this.recordDetails.lpr_exit_record.license_plate_detected))
          ) {
            return true;
          }
        }
      }

      return false;
    },

    allDetectedLicensePlates(): Array<string> {
      let licensePlates = [] as Array<string>;
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.license_plate_detected
      ) {
        licensePlates.push(
          this.recordDetails.lpr_entry_record.license_plate_detected
        );
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.license_plate_detected
      ) {
        licensePlates.push(
          this.recordDetails.lpr_exit_record.license_plate_detected
        );
      }
      if (
        this.recordDetails &&
        this.recordDetails.merged_parking_timeline_records &&
        this.recordDetails.merged_parking_timeline_records.length > 0
      ) {
        for (const record of this.recordDetails
          .merged_parking_timeline_records) {
          if (
            record.event_type == "zone_lpr_event" &&
            (record.event_data as LicensePlateRecognitionHistory)
              .license_plate_detected
          ) {
            licensePlates.push(
              (record.event_data as LicensePlateRecognitionHistory)
                .license_plate_detected
            );
          }
        }
      }
      licensePlates = licensePlates.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      return licensePlates;
    },
  },

  filters: {
    formatDurationSeconds,
    formatDurationShowSeconds,
  },

  async mounted() {
    await this.initData();
  },
  methods: {
    preloadImage() {
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.lpr_image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_entry_record.lpr_image_path_url;
        image.onload = () => {
          this.showLprEntryImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.crop_image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_entry_record.crop_image_path_url;
        image.onload = () => {
          this.showVehicleEntryImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_entry_record.image_path_url;
        image.onload = () => {
          this.showDetectionEntryImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.lpr_image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_exit_record.lpr_image_path_url;
        image.onload = () => {
          this.showLprExitImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.crop_image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_exit_record.crop_image_path_url;
        image.onload = () => {
          this.showVehicleExitImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_exit_record.image_path_url;
        image.onload = () => {
          this.showDetectionExitImage = true;
        };
      }
    },

    async initData() {
      this.isEditing = false;
      try {
        this.isLoading = true;
        let recordDetails = await api.getVehicleParkingUsageRecordDetails(
          this.recordId
        );
        if (recordDetails) {
          this.recordDetails = recordDetails;
          // Also, load Parking Lot info to compute showAnprFields
          this.fetchParkingLotData(recordDetails.parking_lot_id);

          await this.addRecordImagesToViewer();
          this.preloadImage();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchParkingLotData(lotId: number) {
      let parkingLotInfo = await api.getParkingLotBasicInfo(lotId);
      if (parkingLotInfo) {
        this.parkingLotInfo = parkingLotInfo;
      }
    },

    blobToBase64(blob: Blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    async fetchAuthenticatedImage(imageUrl: string) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return "";
        }
        const response = await fetch(imageUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const blob = await response.blob();
        return await this.blobToBase64(blob);
      } catch (error) {
        console.error("Error fetching image:", error);
        return "";
      }
    },

    /**
     * Add all entry/exit and parking images to viewer images list.
     */
    async addRecordImagesToViewer() {
      let imageIndexCount = 0;
      this.images = [];
      if (this.recordDetails?.lpr_entry_record?.lpr_image_path_url) {
        this.imagesShowIndexes.entry_lpr = -1;
        this.images.push(
          this.recordDetails?.lpr_entry_record?.lpr_image_path_url
        );
        this.imagesShowIndexes.entry_lpr = imageIndexCount;
        imageIndexCount++;
      }
      if (this.recordDetails?.lpr_entry_record?.image_path_url) {
        this.imagesShowIndexes.entry_detection = -1;
        this.images.push(this.recordDetails?.lpr_entry_record?.image_path_url);
        this.imagesShowIndexes.entry_detection = imageIndexCount;
        imageIndexCount++;
      }
      if (this.recordDetails?.lpr_entry_record?.crop_image_path_url) {
        this.imagesShowIndexes.entry_vehicle = -1;
        this.images.push(
          this.recordDetails?.lpr_entry_record?.crop_image_path_url
        );
        this.imagesShowIndexes.entry_vehicle = imageIndexCount;
        imageIndexCount++;
      }
      this.imagesShowIndexes.parking = [];
      if (this.recordDetails?.merged_parking_timeline_records) {
        for (const record of this.recordDetails
          ?.merged_parking_timeline_records) {
          if (record.event_type == "spot_tracking_event") {
            let historyRecord = record.event_data as ParkingHistory;
            if (historyRecord.clean_image_url != null) {
              const clean_image_url = await this.fetchAuthenticatedImage(
                historyRecord.clean_image_url
              );
              this.parkingHistoryCleanImages.push({
                id: historyRecord.id,
                url: clean_image_url as string,
              });
              this.images.push(clean_image_url as string);
              this.imagesShowIndexes.parking.push(imageIndexCount);
              imageIndexCount++;
            }
          } else if (record.event_type == "zone_lpr_event") {
            let historyRecord =
              record.event_data as LicensePlateRecognitionHistory;
            if (historyRecord.lpr_image_path_url) {
              this.imagesShowIndexes.exit_lpr = -1;
              this.images.push(historyRecord.lpr_image_path_url);
              this.imagesShowIndexes.parking.push(imageIndexCount);
              imageIndexCount++;
            }
            if (historyRecord.image_path_url) {
              this.imagesShowIndexes.exit_detection = -1;
              this.images.push(historyRecord.image_path_url);
              this.imagesShowIndexes.parking.push(imageIndexCount);
              imageIndexCount++;
            }
            if (historyRecord.crop_image_path_url) {
              this.imagesShowIndexes.exit_vehicle = -1;
              this.images.push(historyRecord.crop_image_path_url);
              this.imagesShowIndexes.parking.push(imageIndexCount);
              imageIndexCount++;
            }
          }
        }
      }

      if (this.recordDetails?.lpr_exit_record?.lpr_image_path_url) {
        this.imagesShowIndexes.exit_lpr = -1;
        this.images.push(
          this.recordDetails?.lpr_exit_record?.lpr_image_path_url
        );
        this.imagesShowIndexes.exit_lpr = imageIndexCount;
        imageIndexCount++;
      }
      if (this.recordDetails?.lpr_exit_record?.image_path_url) {
        this.imagesShowIndexes.exit_detection = -1;
        this.images.push(this.recordDetails?.lpr_exit_record?.image_path_url);
        this.imagesShowIndexes.exit_detection = imageIndexCount;
        imageIndexCount++;
      }
      if (this.recordDetails?.lpr_exit_record?.crop_image_path_url) {
        this.imagesShowIndexes.exit_vehicle = -1;
        this.images.push(
          this.recordDetails?.lpr_exit_record?.crop_image_path_url
        );
        this.imagesShowIndexes.exit_vehicle = imageIndexCount;
        imageIndexCount++;
      }
    },

    removeHistoryRecord(index: number) {
      if (!this.recordDetails) {
        return;
      }
      this.recordDetails.parking_history_records = [
        ...this.recordDetails.parking_history_records.slice(0, index),
        ...this.recordDetails.parking_history_records.slice(index + 1),
      ];
    },
    show(index: number) {
      this.$viewerApi({
        options: {
          initialViewIndex: index,
          focus: false,
          button: false,
        },
        images: this.images,
      });
    },
    getParkingEventImageIndex(imageUrl: string) {
      let imageIndex = this.images.indexOf(imageUrl);
      return imageIndex;
    },

    closePopup() {
      this.showLprEntryImage = false;
      this.showDetectionEntryImage = false;
      this.showVehicleEntryImage = false;
      this.showLprExitImage = false;
      this.showDetectionExitImage = false;
      this.showVehicleExitImage = false;
      this.$emit("close-form");
    },

    openInNewTab(url: string) {
      window.open(url, "_blank");
    },

    async saveRecord() {
      if (this.recordDetails && this.recordDetails.lpr_entry_record) {
        this.recordDetails.lpr_entry_record.vehicle_type =
          this.recordDetails.lpr_entry_record.vehicle_type &&
          this.recordDetails.lpr_entry_record.vehicle_type.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_type
            : null;
        this.recordDetails.lpr_entry_record.vehicle_brand =
          this.recordDetails.lpr_entry_record.vehicle_brand &&
          this.recordDetails.lpr_entry_record.vehicle_brand.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_brand
            : null;
        this.recordDetails.lpr_entry_record.vehicle_color =
          this.recordDetails.lpr_entry_record.vehicle_color &&
          this.recordDetails.lpr_entry_record.vehicle_color.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_color
            : null;
        this.recordDetails.lpr_entry_record.vehicle_country =
          this.recordDetails.lpr_entry_record.vehicle_country &&
          this.recordDetails.lpr_entry_record.vehicle_country.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_country
            : null;
        this.recordDetails.lpr_entry_record.vehicle_region =
          this.recordDetails.lpr_entry_record.vehicle_region &&
          this.recordDetails.lpr_entry_record.vehicle_region.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_region
            : null;
      }
      if (this.recordDetails && this.recordDetails.lpr_exit_record) {
        this.recordDetails.lpr_exit_record.vehicle_type =
          this.recordDetails.lpr_exit_record.vehicle_type &&
          this.recordDetails.lpr_exit_record.vehicle_type.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_type
            : null;
        this.recordDetails.lpr_exit_record.vehicle_brand =
          this.recordDetails.lpr_exit_record.vehicle_brand &&
          this.recordDetails.lpr_exit_record.vehicle_brand.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_brand
            : null;
        this.recordDetails.lpr_exit_record.vehicle_color =
          this.recordDetails.lpr_exit_record.vehicle_color &&
          this.recordDetails.lpr_exit_record.vehicle_color.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_color
            : null;
        this.recordDetails.lpr_exit_record.vehicle_country =
          this.recordDetails.lpr_exit_record.vehicle_country &&
          this.recordDetails.lpr_exit_record.vehicle_country.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_country
            : null;
        this.recordDetails.lpr_exit_record.vehicle_region =
          this.recordDetails.lpr_exit_record.vehicle_region &&
          this.recordDetails.lpr_exit_record.vehicle_region.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_region
            : null;
      }

      let recordUpdated = await api.putVehicleParkingUsageRecordDetails(
        this.recordId,
        this.recordDetails
      );
      if (recordUpdated) {
        this.$dialog.message.info("Saved changes successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.closePopup();
      } else {
        this.$dialog.message.error(
          "Unable to save changes. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async approveRecord() {
      let recordUpdated = await api.approveVehicleParkingUsageRecordDetails(
        this.recordId
      );
      if (recordUpdated) {
        this.$dialog.message.info("Approved and Verified successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.closePopup();
      } else {
        this.$dialog.message.error(
          "Unable to approve record. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async rejectRecord() {
      let recordUpdated = await api.rejectVehicleParkingUsageRecordDetails(
        this.recordId
      );
      if (recordUpdated) {
        this.$dialog.message.info("Rejected ANPR matching.", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.closePopup();
      } else {
        this.$dialog.message.error(
          "Unable to reject record. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    showZoneLprEntryWithLotEntry(eventId: number, created_at: string) {
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.id == eventId &&
        this.recordDetails.lpr_entry_record.created_at == created_at
      ) {
        return true;
      }
      return false;
    },

    showZoneLprExitWithLotExit(eventId: number, created_at: string) {
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.id == eventId &&
        this.recordDetails.lpr_exit_record.created_at == created_at
      ) {
        return true;
      }
      return false;
    },

    checkIfZoneLprEventShouldBeShown(eventId: number) {
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.id == eventId
      ) {
        return false;
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.id == eventId
      ) {
        return false;
      }
      return true;
    },

    getCleanImageBlob(id: number) {
      let cleanImage = this.parkingHistoryCleanImages.find(
        (img) => img.id == id
      );

      if (cleanImage) {
        return cleanImage.url;
      }
      return "";
    },

    getLPRIgnoredFullText(is_ignored: string) {
      switch (is_ignored) {
        case VehicleParkingUsageTrackingRecordIgnored.lp_confidence_low:
          return "Low License Plate Confidence Score";
        case VehicleParkingUsageTrackingRecordIgnored.orientation_confidence_low:
          return "Low Vehicle Orientation/Pose Score";
        case VehicleParkingUsageTrackingRecordIgnored.type_confidence_low:
          return "Low Vehicle Type Score";
        case VehicleParkingUsageTrackingRecordIgnored.make_model_confidence_low:
          return "Low Make Model Confidence Score";
        case VehicleParkingUsageTrackingRecordIgnored.color_confidence_low:
          return "Low Color Confidence Score";
        case VehicleParkingUsageTrackingRecordIgnored.region_confidence_low:
          return "Low Region Confidence Score";
        case VehicleParkingUsageTrackingRecordIgnored.d_score_low:
          return "Low D Score";
        case VehicleParkingUsageTrackingRecordIgnored.direction_not_in_range:
          return "Direction not in Range";
        case VehicleParkingUsageTrackingRecordIgnored.duplicate_lp_detection:
          return "Duplicate License Plate Detected";
        case VehicleParkingUsageTrackingRecordIgnored.direction_mismatch:
          return "Direction Mismatch";
        default:
          return "Low Confidence Score";
      }
    },

    getTimeInParkingLot() {
      if (this.recordDetails?.lpr_entry_record) {
        const createdAt = this.recordDetails?.lpr_entry_record.created_at;
        const diff = dayjs
          .utc(new Date())
          .diff(dayjs.utc(createdAt), "seconds");
        return diff;
      }
    },

    getParkingTime() {
      if (this.recordDetails?.parking_history_records?.length) {
        const createdAt =
          this.recordDetails?.parking_history_records[0].created_at;
        const diff = dayjs
          .utc(new Date())
          .diff(dayjs.utc(createdAt), "seconds");
        return diff;
      } else {
        return null;
      }
    },
  },

  watch: {
    needsInit(showingData) {
      if (showingData) {
        this.initData();
      } else {
        this.showLprEntryImage = false;
        this.showVehicleEntryImage = false;
        this.showDetectionEntryImage = false;
        this.showLprExitImage = false;
        this.showVehicleExitImage = false;
        this.showDetectionExitImage = false;
      }
    },
    recordId() {
      this.initData();
    },
  },
});
